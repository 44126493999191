import axios, { AxiosResponse } from 'axios'
import SimpleCrypto from 'simple-crypto-js'
import { APIURL } from './APIBase'

export const mediaBaseURL = APIURL
export const defaultMissingImgURL = APIURL + '/uploads/noimage.jpg'
export const forgotPwAPIURL = APIURL + '/auth/forgot-password'
export const pwResetPageURL = APIURL + '/auth/reset-password' // URL must also be configured in the settings of API app.
export const datetimeFetchURL = '//dart-ddu.dk/ssi-pages/current-datetime.shtml'
export const guestUser = 'ddustrapiguest@zyberland.dk'
export const guestPWord = 'DDU-API-guest01'
export const guestGroupId = 1 // Group "Gæster"
export const defaultFrontendGroupId = 1 // Group "Gæster"
export const defaultAdminBackendGroupId = 5 // Group "Klubmedlemmer"
export const defaultClubManagerGroupId = 3 // Group "Klubansvarlig"
export const defaultClubCaptainGroupId = 4 // Group "Holdkaptajner"
export const defaultSuperAdminGroupId = 2 // Group "Administratorer"
export const defaultClubId = 22 // "Gæsteklubben"

export interface LogoObj {
  id: null
  name: string
  alternativeText: string
  width: string
  height: string
  formats: { thumbnail: { name: string, hash: string, ext: string, mime: string, width: number, height: number, size: number, path: string | null, url: string },
  large: { name: string, hash: string, ext: string, mime: string, width: number, height: number, size: number, path: string | null, url: string },
  medium: { name: string, hash: string, ext: string, mime: string, width: number, height: number, size: number, path: string | null, url: string },
  small: { name: string, hash: string, ext: string, mime: string, width: number, height: number, size: number, path: string | null, url: string }
  }
  url: string
  mime: string
}

class BaseFunctions {
  public logoObj = {} as LogoObj[]
  private defaultPPhraseKY = 'KRH)G5w63+Wfa9u*M:cUEKh;Vrz.&]Jg'

  public setLogoMediaObjFromStr (jsonLogoData: string) : LogoObj[] {
    this.logoObj = JSON.parse(jsonLogoData) as LogoObj[]

    return this.logoObj
  }

  public setLogoMediaObjFromObj (jsonLogoData: Record<string, unknown>) : LogoObj[] {
    this.logoObj = JSON.parse(JSON.stringify(jsonLogoData)) as LogoObj[]

    return this.logoObj
  }

  public getLogoMediaObj () : LogoObj[] {
    return this.logoObj
  }

  public isEmail (email : string) : boolean {
    const re = /^[^\s@]+@[^\s@]+$/
    return re.test(email)
  }

  // This function only works when DOM is available such as when running the code in a browser client. Running the code on a server with nodeJS does not work (unless you use for example JSDOM module package)
  public convertHTMLToText (htmlData: string) : string {
    const doc = new DOMParser().parseFromString(htmlData, 'text/html')

    return doc.body.textContent || ''
  }

  public calculateAge (birthDate: string, otherDate: string) {
    const birthDateObj = new Date(birthDate)
    const otherDateObj = new Date(otherDate)

    let years = (otherDateObj.getFullYear() - birthDateObj.getFullYear())

    if (otherDateObj.getMonth() < birthDateObj.getMonth() ||
      (otherDateObj.getMonth() === birthDateObj.getMonth() && otherDateObj.getDate() < birthDateObj.getDate())) {
      years--
    }

    return years
  }

  // Return encrypted string of input data string, that is encrypted with default key.
  public defEnrcpyt (inputData = '') : string {
    let retVal = ''

    if (inputData !== null && inputData.length > 0) {
      const simpleCrypto = new SimpleCrypto(this.defaultPPhraseKY)
      retVal = simpleCrypto.encrypt(inputData)
    }

    return retVal
  }

  // Return decrypted string of input data string, that was encrypted with default key.
  public defDercpyt (inputData = '') : string {
    let retVal = ''

    if (inputData !== null && inputData.length > 0) {
      const simpleCrypto = new SimpleCrypto(this.defaultPPhraseKY)
      retVal = simpleCrypto.decrypt(inputData).toString()
    }

    return retVal
  }

  public async getDatetimeFromServer () : Promise<AxiosResponse<any>> {
    const debugMode = false // Set this value to true when testing locally and set it to false before building the app for production.

    if (debugMode) {
      return { data: '2024-10-29T08:00:30.000Z', status: 200, statusText: 'OK', headers: '', config: {} }
    } else {
      try {
        const { data, status, statusText, headers, config } = await axios.get(datetimeFetchURL)
        return { data, status, statusText, headers, config }
      } catch (error) {
        console.error(error)
        return { data: error, status: 0, statusText: 'Error', headers: '', config: {} }
      }
    }
  }

  // Move an item within an array to another index position. (Alternative version.)
  public moveItemInArray2<T> (workArray: T[], fromIndex: number, toIndex: number): T[] {
    if (toIndex === fromIndex) {
      return workArray
    }

    const moveArrayItem = (array: any[], to: number, from: number) => {
      const item = array[from]
      console.log('[moveArrayItem()] Item = ' + JSON.stringify(item))
      array.splice(from, 1)
      console.log('[moveArrayItem()] array after delete = ' + JSON.stringify(array))
      array.splice(to, 0, item)
      return array
    }

    workArray = moveArrayItem(workArray, toIndex, fromIndex)

    return workArray
  }

  // Move an item within an array to another index position.
  public moveItemInArray<T> (workArray: T[], fromIndex: number, toIndex: number): T[] {
    if (toIndex === fromIndex) {
      return workArray
    }

    const target = workArray[fromIndex]
    const increment = toIndex < fromIndex ? -1 : 1

    for (let k = fromIndex; k !== toIndex; k += increment) {
      workArray[k] = workArray[k + increment]
    }
    workArray[toIndex] = target

    return workArray
  }

  /* public obtainToken () : any[] {
    let retVal: string[]
    axios.post(APIURL + '/auth/local', {
      identifier: this.guestUser,
      password: this.guestPWord
    })
    .then((response) => {
      retVal = response.data
    })
    .catch (error) {
      retVal = error
    }

    return retVal
  } */
}

export default new BaseFunctions()
